import { ReactComponent as LeftArrow } from "../../assest/svg/LeftArrow.svg";
import styled, { css } from "styled-components";
import { useNavigate, useParams } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { getPeopleBodyDetail } from "../../api/api";
import Image from "../../components/Image";
import dayjs from "dayjs";
import ImagePreview from "../../components/ImagePreview";
const ScrollToTopOnRender = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>{children}</>;
};
const ContentsDetailPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [filePath, setFilePath] = useState(null);

  const [detail, setDetail] = useState();
  const { id } = useParams();

  const [form, setForm] = useState({
    projectDt: "",
    description: "",
  });

  useEffect(() => {
    getPeopleBodyDetail({ id })
      .then((res) => {
        setDetail(res.data.child);
        setForm(res.data.parent[0]);
      })
      .catch((err) => {
        setDetail([]);
        setForm({});
      });
  }, [id]);

  return (
    <ScrollToTopOnRender>
      <Container isMobile={isMobile}>
        <Detail isMobile={isMobile}>
          <CircleBtn onClick={() => navigate("/contents")}>
            <LeftArrow />
          </CircleBtn>
        </Detail>

        <div className="header">
          {/* aavec 요청사항으로 날짜 제거 20250127 */}
          {/* <div className="date"> */}
          {/* {dayjs(form?.projectDt).format("YYYY.MM.DD")} */}
          {/* </div> */}
          <div className="description"> {form?.description}</div>
        </div>

        <ImageList isMobile={isMobile}>
          {detail
            ?.slice()
            ?.sort((a, b) => a?.order - b?.order)
            .map((item) => {
              return (
                <ImageContainer isMobile={isMobile}>
                  <Image
                    src={item.filePath}
                    alt="test"
                    onClick={() => setFilePath(item.filePath)}
                  />
                </ImageContainer>
              );
            })}
        </ImageList>

        {filePath !== null && (
          <ImagePreview
            filePath={filePath}
            handleClose={() => setFilePath(null)}
          />
        )}
      </Container>
    </ScrollToTopOnRender>
  );
};

const ImageContainer = styled.div`
  width: 30rem;
  overflow: hidden;
  position: relative;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}

  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }
`;

const Detail = styled.div`
  width: 100%;
  padding-left: 40px;
  display: flex;
`;

const ImageList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  display: grid;
  padding: 40px;
  width: 100%;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 1.3rem;
      img {
        width: 86%;
        height: 100%;
        object-fit: scale-down;
      }
    `}
`;

const CircleBtn = styled.div`
  background: #ffffff33;
  border-radius: 50%;
  padding: 10px;
  align-items: left;
  cursor: pointer;

  svg {
    width: 40px;
  }

  &:hover {
    filter: brightness(50%);
    transition: 0.4s ease;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 20rem;

  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: white;
    align-items: center;
    .date {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .description {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 20px;
    `}
`;

export default ContentsDetailPage;
