import { all, fork, call, put, takeLatest } from "redux-saga/effects";

import * as contentsApi from "../../api/contentsApi";
import { contentsAction } from "./contentsSlice";

function* insertPeopleBody({ payload }) {
  try {
    const resultData = yield call(contentsApi.insertPeopleBody, payload);

    yield put(contentsAction.insertPeopleBodySuccess(resultData));
  } catch (error) {
    yield put(contentsAction.insertPeopleBodyError());
  }
}

function* getPeopleBodyList({ payload }) {
  try {
    const resultData = yield call(contentsApi.getPeopleBodyList, payload);

    yield put(contentsAction.getPeopleBodyListSuccess(resultData));
  } catch (error) {
    yield put(contentsAction.getPeopleBodyListError());
  }
}

function* getPeopleBodyDetail({ payload }) {
  try {
    const resultData = yield call(contentsApi.getPeopleBodyDetail, payload);

    yield put(contentsAction.getPeopleBodyDetailSuccess(resultData));
  } catch (error) {
    yield put(contentsAction.getPeopleBodyDetailError());
  }
}

function* deletePeopleBodyDetail({ payload }) {
  try {
    yield call(contentsApi.deletePeopleBodyDetail, payload);

    yield put(contentsAction.deletePeopleBodyDetailSuccess());
  } catch (error) {
    yield put(contentsAction.deletePeopleBodyDetailError());
  }
}

function* changeOrder({ payload }) {
  try {
    const resultData = yield call(contentsApi.changeOrder, payload);

    yield put(contentsAction.changeOrderSuccess(resultData));
  } catch (error) {
    yield put(contentsAction.changeOrderError());
  }
}

function* updateItem({ payload }) {
  try {
    const resultData = yield call(contentsApi.updateItem, payload);

    yield put(contentsAction.updateItemSuccess(resultData));
  } catch (error) {
    yield put(contentsAction.updateItemError());
  }
}

export function* watchInsertPeopleBody() {
  yield takeLatest(contentsAction.insertPeopleBody, insertPeopleBody);
}

export function* watchgetPeopleBodyList() {
  yield takeLatest(contentsAction.getPeopleBodyList, getPeopleBodyList);
}
export function* watchGetPeopleBodyDetail() {
  yield takeLatest(contentsAction.getPeopleBodyDetail, getPeopleBodyDetail);
}
export function* watchDeletePeopleBodyDetail() {
  yield takeLatest(
    contentsAction.deletePeopleBodyDetail,
    deletePeopleBodyDetail
  );
}
export function* watchChangeOrder() {
  yield takeLatest(contentsAction.changeOrder, changeOrder);
}
export function* watchUpdateItem() {
  yield takeLatest(contentsAction.updateItem, updateItem);
}

function* rootSaga() {
  yield all([
    fork(watchInsertPeopleBody),
    fork(watchGetPeopleBodyDetail),
    fork(watchgetPeopleBodyList),
    fork(watchDeletePeopleBodyDetail),
    fork(watchChangeOrder),
    fork(watchUpdateItem),
  ]);
}

export default rootSaga;
