import { all, fork, call, put, takeLatest } from "redux-saga/effects";

import * as aboutApi from "../../api/aboutApi";
import { aboutAction } from "./aboutSlice";

function* insertScrolling({ payload }) {
  console.log(payload);

  try {
    const resultData = yield call(aboutApi.insertScrolling, payload);

    yield put(aboutAction.insertScrollingSuccess(resultData));
  } catch (error) {
    yield put(aboutAction.insertScrollingError());
  }
}

function* getScrollingList({ payload }) {
  try {
    const resultData = yield call(aboutApi.getScrollingList, payload);

    yield put(aboutAction.getScrollingListSuccess(resultData));
  } catch (error) {
    yield put(aboutAction.getScrollingListError());
  }
}

function* deleteAboutItems({ payload }) {
  try {
    const resultData = yield call(aboutApi.deleteAboutItems, payload);

    yield put(aboutAction.deleteAboutItemsSuccess(resultData));
  } catch (error) {
    yield put(aboutAction.deleteAboutItemsError());
  }
}

function* getDetail({ payload }) {
  try {
    const resultData = yield call(aboutApi.getDetail, payload);

    yield put(aboutAction.getDetailSuccess(resultData));
  } catch (error) {
    yield put(aboutAction.getDetailError());
  }
}

function* changeOrder({ payload }) {
  try {
    const resultData = yield call(aboutApi.changeOrder, payload);

    yield put(aboutAction.changeOrderSuccess(resultData));
  } catch (error) {
    yield put(aboutAction.changeOrderError());
  }
}

function* updateItem({ payload }) {
  try {
    const resultData = yield call(aboutApi.updateItem, payload);

    yield put(aboutAction.updateItemSuccess(resultData));
  } catch (error) {
    yield put(aboutAction.updateItemError());
  }
}

export function* watchInsertScrolling() {
  yield takeLatest(aboutAction.insertScrolling, insertScrolling);
}

export function* watchGetScrollingList() {
  yield takeLatest(aboutAction.getScrollingList, getScrollingList);
}
export function* watchDeleteAboutItems() {
  yield takeLatest(aboutAction.deleteAboutItems, deleteAboutItems);
}
export function* watchGetDetail() {
  yield takeLatest(aboutAction.getDetail, getDetail);
}
export function* watchChangeOrder() {
  yield takeLatest(aboutAction.changeOrder, changeOrder);
}
export function* watchUpdateItem() {
  yield takeLatest(aboutAction.updateItem, updateItem);
}

function* rootSaga() {
  yield all([
    fork(watchInsertScrolling),
    fork(watchGetScrollingList),
    fork(watchDeleteAboutItems),
    fork(watchGetDetail),
    fork(watchChangeOrder),
    fork(watchUpdateItem),
  ]);
}

export default rootSaga;
