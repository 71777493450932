import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

// 초기 상태
const ChannelInitialState = {
  contentsList: [],
  isLoading: false,
  error: null,
  contentsDetail: {},
};

const slice = createSlice({
  name: "contents",
  initialState: ChannelInitialState,
  reducers: {
    insertPeopleBody(state) {
      state.actionResult = "ADD_REQ";
      state.isLoading = true;
      state.error = null;
    },
    insertPeopleBodySuccess(state) {
      state.actionResult = "ADD_OK";
      state.isLoading = false;
      state.error = null;
    },
    insertPeopleBodyError(state) {
      state.channelList = [];
      state.actionResult = "ADD_ERR";
      state.isLoading = false;
    },
    getPeopleBodyList(state) {
      state.actionResult = "LIST_REQ";
      state.isLoading = true;
      state.error = null;
    },
    getPeopleBodyListSuccess(state, { payload: contentsList }) {
      state.contentsList = contentsList.data;
      state.actionResult = "LIST_OK";
      state.isLoading = false;
      state.error = null;
    },
    getPeopleBodyListError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "LIST_ERR";
      state.isLoading = false;
    },
    deletePeopleBodyDetail(state) {
      state.actionResult = "DEL_REQ";
      state.isLoading = true;
      state.error = null;
    },
    deletePeopleBodyDetailSuccess(state) {
      state.actionResult = "DEL_OK";
      state.isLoading = false;
      state.error = null;
    },
    deletePeopleBodyDetailError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "DEL_ERR";
      state.isLoading = false;
    },
    getPeopleBodyDetail(state) {
      state.actionResult = "DETAIL_REQ";
      state.isLoading = true;
      state.error = null;
    },
    getPeopleBodyDetailSuccess(state, { payload: detail }) {
      state.actionResult = "DETAIL_OK";
      state.isLoading = false;
      state.error = null;
      state.contentsDetail = detail.data;
    },
    getPeopleBodyDetailError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "DETAIL_ERR";
      state.isLoading = false;
    },
    changeOrder(state) {
      state.actionResult = "ORDER_REQ";
      state.isLoading = true;
      state.error = null;
    },
    changeOrderSuccess(state) {
      state.actionResult = "ORDER_OK";
      state.isLoading = false;
      state.error = null;
    },
    changeOrderError(state) {
      state.channelList = [];
      state.actionResult = "ORDER_ERR";
      state.isLoading = false;
    },
    updateItem(state) {
      state.actionResult = "UPDATE_REQ";
      state.isLoading = true;
      state.error = null;
    },
    updateItemSuccess(state) {
      state.actionResult = "UPDATE_OK";
      state.isLoading = false;
      state.error = null;
    },
    updateItemError(state) {
      state.channelList = [];
      state.actionResult = "UPDATE_ERR";
      state.isLoading = false;
    },
  },
});
const selectContentsList = createDraftSafeSelector(
  (state) => state.contentsList,
  (contentsList) => ({ contentsList })
);
const selectContentsDetail = createDraftSafeSelector(
  (state) => state.contentsDetail,
  (contentsDetail) => ({ contentsDetail })
);
const selectStatus = createDraftSafeSelector(
  (state) => state.actionResult,
  (state) => state.isLoading,
  (state) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error })
);

export const contentsSelector = {
  contentsList: (state) => selectContentsList(state[CONTENTS]),
  contentsDetail: (state) => selectContentsDetail(state[CONTENTS]),
  status: (state) => selectStatus(state[CONTENTS]),
};

export const CONTENTS = slice.name;
export const contentsReducer = slice.reducer;
export const contentsAction = slice.actions;
