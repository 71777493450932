import { combineReducers } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import aboutSaga from "./about/aboutSaga";
import mainSaga from "./main/mainSaga";
import contentsSaga from "./contents/contentsSaga";

import { ABOUT, aboutReducer } from "./about/aboutSlice";
import { MAIN, mainReducer } from "./main/mainSlice";
import { CONTENTS, contentsReducer } from "./contents/contentsSlice";

const createRootReducer = (history) =>
  combineReducers({
    [ABOUT]: aboutReducer,
    [MAIN]: mainReducer,
    [CONTENTS]: contentsReducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([aboutSaga(), mainSaga(), contentsSaga()]);
}

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: true,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      sagaMiddleware,
    ],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
