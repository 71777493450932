import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

// 초기 상태
const ChannelInitialState = {
  mainList: [],
  isLoading: false,
  error: null,
  mainDetail: {},
};

const slice = createSlice({
  name: "main",
  initialState: ChannelInitialState,
  reducers: {
    insertMainContents(state) {
      state.actionResult = "ADD_REQ";
      state.isLoading = true;
      state.error = null;
    },
    insertMainContentsSuccess(state) {
      state.actionResult = "ADD_OK";
      state.isLoading = false;
      state.error = null;
    },
    insertMainContentsError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "ADD_ERR";
      state.isLoading = false;
    },
    getMainContentsList(state) {
      state.actionResult = "LIST_REQ";
      state.isLoading = true;
      state.error = null;
    },
    getMainContentsListSuccess(state, { payload: mainList }) {
      state.mainList = mainList.data;
      state.actionResult = "LIST_OK";
      state.isLoading = false;
      state.error = null;
    },
    getMainContentsListError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "LIST_ERR";
      state.isLoading = false;
    },
    deleteMainItems(state) {
      state.actionResult = "DEL_REQ";
      state.isLoading = true;
      state.error = null;
    },
    deleteMainItemsSuccess(state) {
      state.actionResult = "DEL_OK";
      state.isLoading = false;
      state.error = null;
    },
    deleteMainItemsError(state, { payload }) {
      state.mainDetail = [];
      state.actionResult = "DEL_ERR";
      state.isLoading = false;
    },
    getMainDetail(state) {
      state.actionResult = "DETAIL_REQ";
      state.isLoading = true;
      state.error = null;
    },
    getMainDetailSuccess(state, { payload: detail }) {
      state.actionResult = "DETAIL_OK";
      state.isLoading = false;
      state.error = null;
      state.mainDetail = detail.data[0];
    },
    getMainDetailError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "DETAIL_ERR";
      state.isLoading = false;
    },
    changeOrder(state) {
      state.actionResult = "ORDER_REQ";
      state.isLoading = true;
      state.error = null;
    },
    changeOrderSuccess(state) {
      state.actionResult = "ORDER_OK";
      state.isLoading = false;
      state.error = null;
    },
    changeOrderError(state) {
      state.channelList = [];
      state.actionResult = "ORDER_ERR";
      state.isLoading = false;
    },
    updateItem(state) {
      state.actionResult = "UPDATE_REQ";
      state.isLoading = true;
      state.error = null;
    },
    updateItemSuccess(state) {
      state.actionResult = "UPDATE_OK";
      state.isLoading = false;
      state.error = null;
    },
    updateItemError(state) {
      state.channelList = [];
      state.actionResult = "UPDATE_ERR";
      state.isLoading = false;
    },
  },
});
const selectMainList = createDraftSafeSelector(
  (state) => state.mainList,
  (mainList) => ({ mainList })
);
const selectMainDetail = createDraftSafeSelector(
  (state) => state.mainDetail,
  (mainDetail) => ({ mainDetail })
);
const selectStatus = createDraftSafeSelector(
  (state) => state.actionResult,
  (state) => state.isLoading,
  (state) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error })
);

export const mainSelector = {
  mainList: (state) => selectMainList(state[MAIN]),
  mainDetail: (state) => selectMainDetail(state[MAIN]),
  status: (state) => selectStatus(state[MAIN]),
};

export const MAIN = slice.name;
export const mainReducer = slice.reducer;
export const mainAction = slice.actions;
