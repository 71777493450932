import React from "react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";

import Main from "../pages/common/Main";
import ContentsPage from "../pages/contents/ContentsPage";
import ContentsDetailPage from "../pages/contents/ContentsDetailPage";

import AboutPage from "../pages/about/AboutPage";
import MainPage from "../pages/main/MainPage";
import CMSLogin from "../pages/cms/CMSLogin";
import CMSLayout from "../pages/cms/CMSLayout";
import ContentsManagePage from "../pages/cms/about/ContentsManagePage";
import PeopleBodyPage from "../pages/cms/contents/PeopleBodyPage";
import MainContentsManagePage from "../pages/cms/main/MainContentsManagePage";
import PeopleBodyListPage from "../pages/cms/contents/PeopleBodyListPage";
import MainContentsListPage from "../pages/cms/main/MainContentsListPage";
import ContentsManageListPage from "../pages/cms/about/ContentsManageListPage";
import ContentsManageModifyPage from "../pages/cms/about/ContentsManageModifyPage";
import PeopleBodyModifyPage from "../pages/cms/contents/PeopleBodyModifyPage";
import MainContentsModifyPage from "../pages/cms/main/MainContentsModifyPage";

const Routes = () => {
  const rootRoutes = {
    path: "/",
    element: <Main />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      // {
      //   path: "about",
      //   element: <AboutPage />,
      // },
      {
        path: "contents/:urlType?",
        element: <ContentsPage />,
      },
      {
        path: "contents/detail/:id",
        element: <ContentsDetailPage />,
      },
    ],
  };

  const cmsAuthRoutes = {
    path: "/cms",
    element: <CMSLogin />,
  };

  const cmsRoutes = {
    path: "/cms/page",
    element: <CMSLayout />,
    children: [
      {
        path: "about/list",
        element: <ContentsManageListPage />,
      },
      {
        path: "human/list",
        element: <PeopleBodyListPage />,
      },
      {
        path: "main/list",
        element: <MainContentsListPage />,
      },
      {
        path: "about/:id?",
        element: <ContentsManagePage />,
      },
      {
        path: "human/:id?",
        element: <PeopleBodyPage />,
      },
      {
        path: "main/:id?",
        element: <MainContentsManagePage />,
      },
      {
        path: "about/detail/:id?",
        element: <ContentsManageModifyPage />,
      },
      {
        path: "human/detail/:id?",
        element: <PeopleBodyModifyPage />,
      },
      {
        path: "main/detail/:id?",
        element: <MainContentsModifyPage />,
      },
    ],
  };

  const noMatchRoutes = {
    path: "*",
    element: <Navigate to="/" />,
  };

  const routes = [rootRoutes, cmsAuthRoutes, cmsRoutes];

  return useRoutes(routes);
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default React.memo(Router);
