import { memo, useState } from "react";
import styled from "styled-components";
import { ReactComponent as BlackLogo } from "../../assest/svg/black-logo.svg";
import Button from "../../components/Button";
import { login } from "../../api/api";
import { useNavigate } from "react-router";
import { TextField } from "@mui/material";

const CMSLogin = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    loginId: "",
    password: "",
  });

  const handleLogin = async () => {
    await login(form).then((accessToken) => {
      if (accessToken !== null) {
        localStorage.setItem("accessToken", accessToken);
        navigate("/cms/page/main/list");
      }
    });
  };

  return (
    <Container>
      <LoginContainer>
        <LoginContents>
          <BlackLogo />

          <FormContainer>
            <FormItem>
              <div className="name">아이디</div>
              <div className="value">
                <TextField
                  className="inputForm"
                  type="text"
                  placeholder="아이디를 입력해주세요."
                  onChange={(e) =>
                    setForm({ ...form, loginId: e.target.value })
                  }
                />
              </div>
            </FormItem>
            <FormItem>
              <div className="name">비밀번호</div>
              <div className="value">
                <TextField
                  className="inputForm"
                  type="password"
                  placeholder="비밀번호를 입력해주세요."
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
              </div>
            </FormItem>
          </FormContainer>
          <div style={{ width: "100%", position: "relative" }}>
            <Button
              fullWidth
              text="로그인"
              type="primary"
              onClick={() => handleLogin()}
            ></Button>
          </div>
        </LoginContents>
      </LoginContainer>
    </Container>
  );
};

const LoginContents = styled.div`
  padding: 60px 70px;
  gap: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  width: 340px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  bottom: 0px;
  z-index: 1;
  font-family: "PretendardBold";
  .name {
    font-size: 1rem;
    font-weight: 700 !important;
  }
  .value {
    width: 100%;
    font-size: 30px;

    .inputForm {
      width: 100%;
      outline: none;
      width: 100%;
      input {
        padding: 1rem 18px !important;
      }
    }
  }
`;

const LoginContainer = styled.div`
  color: black;
  background-color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  gap: 75px;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  color: white;
  background-color: #edf0f4 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default memo(CMSLogin);
