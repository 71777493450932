import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";

import { ReactComponent as LeftArrow } from "../../assest/svg/SlideLeftArrow.svg";
import { ReactComponent as RightArrow } from "../../assest/svg/SlideRightArrow.svg";

const slide = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 40px);
  overflow: hidden;
  margin: auto;
  overflow: hidden;
  left: 0;
  right: 0;
  position: relative;

  width: 100%;
  /* height: 400px; 슬라이더의 높이는 원하는 대로 설정 */
  overflow: hidden;
  position: relative;

  padding-bottom: 1rem;
`;

const Slide = styled.div`
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  animation: ${slide} 1s ease-in-out;
  position: relative;

  #video {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 100%;

      img {
        object-fit: cover;
      }
    `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 컨테이너를 덮으면서 비율을 유지 */
  object-position: center; /* 이미지의 중앙을 기준으로 */
  display: block;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NavButton = styled.button`
  width: 10px;
  height: 10px;

  border-radius: 50%;
  z-index: 1;
  background-color: ${({ isActive }) => (isActive ? "white" : "gray")};
  cursor: pointer;

  &:hover {
    background-color: white;
  }
`;

const MoveableSlider = ({ imagesList, isMobile }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  const showNextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesList.length);
  }, [imagesList]);
  useEffect(() => {
    if (imagesList[currentIndex]?.fileFormat === "video" && videoRef.current) {
      videoRef.current.defaultMuted = true;
      videoRef.current.play();
    } else {
      const interval = setTimeout(showNextSlide, 10000); // 5초마다 슬라이드 전환

      return () => clearInterval(interval);
    }
  }, [currentIndex, imagesList, showNextSlide]);

  useEffect(() => {
    if (imagesList[currentIndex]?.fileFormat === "video") {
      document.body.addEventListener(
        "click",
        function () {
          var video = document.getElementById(imagesList[currentIndex]?.id);
          if (video) {
            video?.play();
          }
        },
        { once: true }
      );
    }
  }, [currentIndex, imagesList]);

  const handleIndex = (type) => {
    let changeIdx = currentIndex;
    if (type === "prev") {
      changeIdx -= 1;
    } else if (type === "next") {
      changeIdx += 1;
    }
    if (changeIdx >= 0 && changeIdx < imagesList.length) {
      setCurrentIndex(changeIdx);
    }
  };

  return (
    <SliderContainer>
      {imagesList
        ?.slice()
        .sort((a, b) => a.order - b.order)
        .map((item, index) => (
          <Slide
            isMobile={isMobile}
            key={index}
            isActive={index === currentIndex}
          >
            {item?.fileFormat === "video" ? (
              <>
                {isMobile ? (
                  <>
                    <Video
                      id={item.id}
                      autoPlay
                      muted
                      playsInline
                      onEnded={showNextSlide}
                      ref={videoRef}
                    >
                      <source src={item.filePath} type={"video/mp4"} />
                    </Video>
                  </>
                ) : (
                  <Video
                    id={item.id}
                    autoPlay
                    muted
                    playsInline
                    onEnded={showNextSlide}
                    ref={videoRef}
                  >
                    <source src={item.filePath} type={"video/mp4"} />
                  </Video>
                )}
              </>
            ) : (
              <Image src={item.filePath} alt={`Slide ${index + 1}`} />
            )}
          </Slide>
        ))}
      <ButtonGroup isMobile={isMobile}>
        <div className="arrow" onClick={() => handleIndex("prev")}>
          <LeftArrow />
        </div>
        <div className="info">{currentIndex + 1 + "/" + imagesList.length}</div>
        <div className="arrow" onClick={() => handleIndex("next")}>
          <RightArrow />
        </div>
      </ButtonGroup>
    </SliderContainer>
  );
};

export default MoveableSlider;

const ButtonGroup = styled.div`
  color: white;
  bottom: 0;
  position: absolute;
  right: 0;
  display: flex;
  gap: 1.3px;
  padding-bottom: 40px;
  padding-right: 40px;
  align-items: center;
  justify-content: center;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-bottom: 20px;
      padding-right: 20px;
    `}

  .arrow {
    text-align: center;
    flex: 1;
    cursor: pointer;
    background: #11111199;
    padding: 0.5rem 0.8rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    text-align: center;
    flex: 2;
    background: #11111199;
    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
