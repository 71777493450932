import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import { getPeopleBodyList } from "../../../api/api";
import StyledTable from "../../../components/Table";
import { useLocation, useNavigate } from "react-router";
import { deletePeopleBodyDetail } from "../../../api/contentsApi";
import { SearchField, StyledInput } from "../about/ContentsManageListPage";
import { ReactComponent as SearchIcon } from "../../../assest/svg/SearchIcon.svg";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import {
  contentsAction,
  contentsSelector,
} from "../../../module/contents/contentsSlice";
import { useDispatch } from "react-redux";
import { itemsPerPage } from "../../../enum";
const PeopleBodyListPage = () => {
  const [list, setList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const { contentsList } = useSelector(contentsSelector.contentsList);
  const { actionResult } = useSelector(contentsSelector.status);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      actionResult === "ORDER_OK" ||
      actionResult === "DEL_OK" ||
      actionResult === "ADD_OK" ||
      actionResult === "UPDATE_OK"
    ) {
      dispatch(contentsAction.getPeopleBodyList());
    } else if (actionResult === "LIST_OK") {
      setList(contentsList);
    }
  }, [actionResult, contentsList, dispatch]);

  useEffect(() => {
    dispatch(contentsAction.getPeopleBodyList());
  }, [dispatch]);

  const thead = {
    projectNm: "프로젝트명",
    type: "카테고리",
    image: "대표 이미지",
    images: "상세 이미지",
  };

  const handleSelectRow = (item) => {
    navigate("/cms/page/human/detail/" + item.id);
  };

  const handleDelete = () => {
    dispatch(contentsAction.deletePeopleBodyDetail(checkList));
  };

  const handleSearch = () => {
    setList((list) =>
      contentsList
        .filter((item) => item.projectNm.includes(searchValue))
        .filter((item) =>
          searchCondition === "total" ? true : item?.type === searchCondition
        )
    );
  };

  const [searchCondition, setSearchCondition] = useState("total");

  const handleSearchCondition = (e) => {
    const { value } = e.target;
    setSearchCondition(value);
  };

  return (
    <Container>
      <Title>인물/바디 프로필 콘텐츠</Title>
      <Section>
        <Header>
          <SectionTitle>
            리스트({list?.length})
            <FormControl sx={{ width: 140, margin: 0 }} size="small">
              <Select
                name="type"
                value={searchCondition}
                onChange={handleSearchCondition}
              >
                <MenuItem value={"total"}>전체</MenuItem>
                <MenuItem value={"people"}>People</MenuItem>
                <MenuItem value={"body"}>Body</MenuItem>
              </Select>
            </FormControl>
            <SearchField>
              <StyledInput
                placeholder="검색할 프로젝트명을 입력해주세요."
                onChange={(e) => setSearchValue(e.target.value)}
              ></StyledInput>
              <SearchIcon onClick={handleSearch} />
            </SearchField>
          </SectionTitle>

          <ButtonGroup>
            <Button type="secondary" text="삭제" onClick={handleDelete}>
              삭제
            </Button>
            <Button
              type="primary"
              text="등록"
              onClick={() => navigate("/cms/page/human")}
            ></Button>
          </ButtonGroup>
        </Header>
        <StyledTable
          data={list}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          thead={thead}
          handleSelectRow={handleSelectRow}
          handleCheckedList={setCheckList}
        />

        <Item></Item>
      </Section>
    </Container>
  );
};

const Item = styled.div`
  .order {
  }
`;

const FileInputArea = styled.div`
  background-color: #f9fafc;
  border: 1px solid #cccccc;
  border-style: dashed;
  padding-top: 37px;
  padding-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #999999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;
const SectionTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 7px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px;
`;
export default memo(PeopleBodyListPage);
