import { createSlice, createDraftSafeSelector } from "@reduxjs/toolkit";

// 초기 상태
const ChannelInitialState = {
  aboutList: [],
  isLoading: false,
  error: null,
  aboutDetail: {},
};

const slice = createSlice({
  name: "about",
  initialState: ChannelInitialState,
  reducers: {
    insertScrolling(state) {
      state.actionResult = "ADD_REQ";
      state.isLoading = true;
      state.error = null;
    },
    insertScrollingSuccess(state) {
      state.actionResult = "ADD_OK";
      state.isLoading = false;
      state.error = null;
    },
    insertScrollingError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "ADD_ERR";
      state.isLoading = false;
    },
    getScrollingList(state) {
      state.actionResult = "LIST_REQ";
      state.isLoading = true;
      state.error = null;
    },
    getScrollingListSuccess(state, { payload: aboutList }) {
      state.aboutList = aboutList.data;
      state.actionResult = "LIST_OK";
      state.isLoading = false;
      state.error = null;
    },
    getScrollingListError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "LIST_ERR";
      state.isLoading = false;
    },
    deleteAboutItems(state) {
      state.actionResult = "DEL_REQ";
      state.isLoading = true;
      state.error = null;
    },
    deleteAboutItemsSuccess(state) {
      state.actionResult = "DEL_OK";
      state.isLoading = false;
      state.error = null;
    },
    deleteAboutItemsError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "DEL_ERR";
      state.isLoading = false;
    },
    getDetail(state) {
      state.actionResult = "DETAIL_REQ";
      state.isLoading = true;
      state.error = null;
    },
    getDetailSuccess(state, { payload: detail }) {
      state.actionResult = "DETAIL_OK";
      state.isLoading = false;
      state.error = null;
      state.aboutDetail = detail.data[0];
    },
    getDetailError(state, { payload }) {
      state.channelList = [];
      state.actionResult = "DETAIL_ERR";
      state.isLoading = false;
    },
    changeOrder(state) {
      state.actionResult = "ORDER_REQ";
      state.isLoading = true;
      state.error = null;
    },
    changeOrderSuccess(state) {
      state.actionResult = "ORDER_OK";
      state.isLoading = false;
      state.error = null;
    },
    changeOrderError(state) {
      state.channelList = [];
      state.actionResult = "ORDER_ERR";
      state.isLoading = false;
    },
    updateItem(state) {
      state.actionResult = "UPDATE_REQ";
      state.isLoading = true;
      state.error = null;
    },
    updateItemSuccess(state) {
      state.actionResult = "UPDATE_OK";
      state.isLoading = false;
      state.error = null;
    },
    updateItemError(state) {
      state.channelList = [];
      state.actionResult = "UPDATE_ERR";
      state.isLoading = false;
    },
  },
});
const selectAboutList = createDraftSafeSelector(
  (state) => state.aboutList,
  (aboutList) => ({ aboutList })
);
const selectAboutDetail = createDraftSafeSelector(
  (state) => state.aboutDetail,
  (aboutDetail) => ({ aboutDetail })
);
const selectStatus = createDraftSafeSelector(
  (state) => state.actionResult,
  (state) => state.isLoading,
  (state) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error })
);

export const aboutSelector = {
  aboutList: (state) => selectAboutList(state[ABOUT]),
  aboutDetail: (state) => selectAboutDetail(state[ABOUT]),
  status: (state) => selectStatus(state[ABOUT]),
};

export const ABOUT = slice.name;
export const aboutReducer = slice.reducer;
export const aboutAction = slice.actions;
