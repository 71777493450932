import { memo } from "react";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";

const Footer = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <Container isMobile={isMobile}>
      <FooterGroup isMobile={isMobile}>
        <FooterObj.Item isMobile={isMobile} isFirst={true}>
          Studio AAVEC
        </FooterObj.Item>

        <FooterObj.Item
          isMobile={isMobile}
          onClick={() => {
            window.location.href = "https://www.instagram.com/studioaavec/";
          }}
        >
          Instagram
        </FooterObj.Item>
        <FooterObj.Item
          isMobile={isMobile}
          onClick={() => {
            window.location.href = "https://pf.kakao.com/_zDCSb";
          }}
          isLast={true}
        >
          Reservation
        </FooterObj.Item>
      </FooterGroup>

      <CopyRight>CEO &nbsp; Sangjin Lee 127-87-02231</CopyRight>
      <CopyRight>
        Location &nbsp; 16-6 Cheongdam-dong, Gangnam-gu, Seoul
      </CopyRight>
      <CopyRight>ⓒ Studio AAVEC All rights reserved.</CopyRight>
    </Container>
  );
};

const CopyRight = styled.div`
  font-size: 14px;
`;

const Item = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 16px;
  padding: 0 12px 0 12px;

  white-space: nowrap;

  ${({ isMobile, isLast }) =>
    isMobile &&
    css`
      padding: 0;
    `}

  ${({ isMobile, isLast }) =>
    isMobile &&
    isLast &&
    css`
      padding-bottom: 1rem;
    `}

  ${({ isFirst }) =>
    isFirst &&
    css`
      padding: 0 12px 0 0;
    `} /* ${({ isLast }) =>
    !isLast &&
    css`
      border-right: 2px solid white;
    `} */
`;

const FooterGroup = styled.div`
  display: flex;
  cursor: default;

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      gap: 1.3rem;
    `}
`;

const FooterObj = Object.assign("Footer", {
  Item,
});

const Container = styled.footer`
  width: calc(100% - 80px);
  z-index: 1;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 40px;
  background-color: #ffffff1a;
  margin-top: 140px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: calc(100% - 38px);
      z-index: 1;
      color: white;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 20px;
      background-color: #ffffff1a;
      margin-top: 137px;
      height: 100%;
    `}
`;

export default memo(Footer);
