import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useParams } from "react-router";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs, { locale } from "dayjs";
import Button from "../../../components/Button";
import { getPeopleBodyDetail, updateItem } from "../../../api/contentsApi";
import PeopleBodyDetailModifyModal from "./PeopleBodyDetailModifyModal";
import Image from "../../../components/Image";
import { useSelector } from "react-redux";
import {
  contentsAction,
  contentsSelector,
} from "../../../module/contents/contentsSlice";
import { useDispatch } from "react-redux";

const PeopleBodyModifyPage = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  const [childList, setChildList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [form, setForm] = useState({
    projectNm: "",
    type: "people",
    description: "",
    child: [],
    fileSize: 0,
  });

  const { id } = useParams();

  const { actionResult } = useSelector(contentsSelector.status);
  const { contentsDetail } = useSelector(contentsSelector.contentsDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (actionResult === "UPDATE_OK") navigate("/cms/page/human/list");
    else if (actionResult === "DETAIL_OK") {
      setForm({
        ...contentsDetail?.parent[0],
        // projectDt: dayjs(contentsDetail?.parent[0].projectDt),
        child: contentsDetail?.child,
      });
    }
  }, [actionResult, navigate, contentsDetail]);

  useEffect(() => {
    dispatch(contentsAction.getPeopleBodyDetail({ id }));
  }, [dispatch, id]);

  const handleFileChange = (file) => {
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        setForm({
          ...form,
          fileSize: file.size,
          fileOriginNm: file.name,
        });
        resolve();
      };
    });
  };

  const handleSave = () => {
    const formData = new FormData();

    const json = {
      ...form,
      files: file,
      updateChildren: childList.filter((item) => item?.hasOwnProperty("id")),
      deleteChildren: deleteList,
      insertChildren: childList
        .filter((item) => !item?.hasOwnProperty("id"))
        .map((_, index) => ({ file: `file${index + 1}` })),
      child: [],
    };
    console.log("json", json);

    if (file !== null) {
      formData.append("file0", file);
    }

    childList
      .filter((item) => !item?.hasOwnProperty("id"))
      .forEach((child, index) => {
        formData.append(`file${index + 1}`, child.file);
      });

    formData.append("json", JSON.stringify(json));

    dispatch(contentsAction.updateItem(formData));
  };

  function filterArray(a, b) {
    let bIds = new Set(b.map((item) => item.id));
    return a.filter((item) => !bIds.has(item.id));
  }

  const handleClose = (data) => {
    setIsDetailModalOpen(false);
    let result = filterArray(form.child, data);
    setDeleteList(result);
    setChildList(data);

    setForm({ ...form, child: data });
  };

  const handleControlForm = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const [isButtonDisable, setIsButtonDisable] = useState(true);

  useEffect(() => {
    if (
      form.fileSize > 0 &&
      form.projectNm.length > 0 &&
      form.child?.length > 0
    ) {
      setIsButtonDisable(false);
    } else {
      setIsButtonDisable(true);
    }
  }, [form, file, childList]);

  return (
    <Container>
      <Title>인물/바디 프로필 콘텐츠</Title>
      <Section>
        <Header>
          <SectionTitle>콘텐츠 등록</SectionTitle>
          <ButtonGroup>
            <Button
              text="취소"
              onClick={() => navigate("/cms/page/human/list")}
            >
              취소
            </Button>
            <Button
              text="저장"
              isDisable={isButtonDisable}
              type="primary"
              onClick={handleSave}
            >
              저장
            </Button>
          </ButtonGroup>
        </Header>
        <FormContainer>
          <div className="formChild">
            <FormItem>
              프로젝트명 <Point>*</Point>
            </FormItem>
            <TextField
              name="projectNm"
              onChange={handleControlForm}
              value={form.projectNm}
              variant="outlined"
              placeholder="프로젝트명을 입력하세요."
              fullWidth={true}
            />
          </div>

          <div className="formChild">
            <FormItem>
              카테고리<Point>*</Point>
            </FormItem>
            <FormControl sx={{ m: 1, maxWidth: 180, margin: 0 }} size="small">
              <Select
                name="type"
                value={form.type}
                onChange={handleControlForm}
              >
                <MenuItem value={"people"}>인물</MenuItem>
                <MenuItem value={"body"}>바디</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* <div className="formChild">
            <FormItem>
              날짜<Point>*</Point>
            </FormItem>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={locale}
            >
              <FormControl sx={{ m: 1, maxWidth: 180, margin: 0 }} size="small">
                <DatePicker
                  name="projectDt"
                  onChange={(value) =>
                    setForm({
                      ...form,
                      projectDt: dayjs(value),
                    })
                  }
                  value={form.projectDt}
                  format="YYYY-MM-DD"
                  slotProps={{ textField: { size: "small" } }}
                  size="small"
                />
              </FormControl>
            </LocalizationProvider>
          </div> */}

          <div className="formChild">
            <FormItem>
              설명<Point>*</Point>
            </FormItem>
            <TextField
              multiline
              name="description"
              placeholder="내용을 입력하세요."
              value={form.description}
              onChange={handleControlForm}
            ></TextField>
          </div>
        </FormContainer>

        <FormItem>
          이미지 <Point>*</Point>
        </FormItem>
        <FileUploader
          multiple={false}
          handleChange={handleFileChange}
          name="file"
          types={["jpg", "jpeg", "png"]}
          label="여기로 이미지를 드래그하거나 파일을 업로드 하세요."
        >
          <FileInputArea>
            여기로 이미지를 드래그하거나 파일을 업로드 하세요.
          </FileInputArea>
        </FileUploader>

        <Preview>
          {form?.fileSize > 0 && (
            <div className="parent">
              <div className="img">
                <img
                  src={imgSrc !== null ? imgSrc : form?.filePath}
                  alt="images"
                />
                <div
                  id="cancel"
                  onClick={() => {
                    setImgSrc(null);
                    setForm({
                      ...form,
                      filePath: null,
                      fileOriginNm: "",
                      fileNm: "",
                      fileSize: 0,
                    });
                  }}
                >
                  X
                </div>
              </div>
              <div className="info">
                <p className="title">{form?.fileOriginNm}</p>
                <p className="size">{form?.fileSize}</p>
              </div>
            </div>
          )}
          <div className="child">
            <div className="child-header">
              <p>총 {form?.child?.length}장</p>

              <Button
                text={"상세 이미지 설정"}
                type={"primary"}
                onClick={() => setIsDetailModalOpen(true)}
              />
            </div>
            <div className="child-body">
              {form?.child
                ?.slice()
                ?.sort((a, b) => a.order - b.order)
                ?.map((item) => (
                  <Image src={item.filePath} alt="test" />
                ))}
            </div>
          </div>
        </Preview>
      </Section>

      <PeopleBodyDetailModifyModal
        open={isDetailModalOpen}
        handleClose={() => setIsDetailModalOpen(false)}
        handleSave={handleClose}
        imageList={form?.child}
      />
    </Container>
  );
};

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .formChild {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & > div {
    padding-bottom: 10px;
  }
`;

export const FormItem = styled.div`
  display: flex;
  color: #67686a;
`;

export const Point = styled.p`
  color: #ff6868;
`;

const Preview = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e6eb;
  border-radius: 4px;

  display: flex;
  gap: 20px;

  .parent {
    display: flex;
    width: 100%;
    gap: 20px;
    padding: 1rem;
  }

  .img {
    width: 180px;
    position: relative;
    aspect-ratio: 1/1;
    img {
      width: 100%;
    }

    #cancel {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      aspect-ratio: 1/1;
      background-color: #000000cc;
      color: white;
      cursor: pointer;
    }
  }

  .child {
    display: flex;
    flex-direction: column;
    background: #f9fafc;
    border-radius: 0px 0px 3px 3px;
    padding: 24px 17px;

    .child-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .child-body {
      display: grid;
      grid-auto-flow: row;
      display: table-cell;
      position: relative;
      gap: 1rem;

      img {
        padding-left: 3px;
      }

      img {
        max-height: 122px;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 6px;

    .size {
      font-size: 1rem;
      color: #999999;
    }
  }
`;

const FileInputArea = styled.div`
  background-color: #f9fafc;
  border: 1px solid #cccccc;
  border-style: dashed;
  padding-top: 37px;
  padding-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #999999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #dedede;
`;
const SectionTitle = styled.div``;
const ButtonGroup = styled.div`
  display: flex;
  gap: 7px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export default memo(PeopleBodyModifyPage);
