import axios from "axios";

// Axios 인스턴스를 생성

let apiUrl = "";
if (window.location.href.includes(".com")) {
  apiUrl = "https://api.studioaavec.com/api";
} else if (window.location.href.includes("3000")) {
  apiUrl = "https://api.studioaavec.com/api";
}
const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
  },
});

// 요청 인터셉터를 추가하여 토큰을 헤더에 포함
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken"); // 토큰을 localStorage 또는 다른 저장소에서 가져옴
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    //  return Promise.reject(error);
  }
);

// 응답 인터셉터를 추가하여 토큰 만료 시 갱신
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    //  return Promise.reject(error);
  }
);

export default api;
