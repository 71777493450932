import { all, fork, call, put, takeLatest } from "redux-saga/effects";

import * as mainApi from "../../api/mainApi";
import { mainAction } from "./mainSlice";

function* insertMainContents({ payload }) {
  try {
    const resultData = yield call(mainApi.insertMainContents, payload);

    yield put(mainAction.insertMainContentsSuccess(resultData));
  } catch (error) {
    yield put(mainAction.insertMainContentsError());
  }
}

function* getMainContentsList({ payload }) {
  try {
    const resultData = yield call(mainApi.getMainContentsList, payload);

    yield put(mainAction.getMainContentsListSuccess(resultData));
  } catch (error) {
    yield put(mainAction.getMainContentsListError());
  }
}

function* getMainDetail({ payload }) {
  try {
    const resultData = yield call(mainApi.getMainDetail, payload);

    yield put(mainAction.getMainDetailSuccess(resultData));
  } catch (error) {
    yield put(mainAction.getMainDetailError());
  }
}

function* deleteMainItems({ payload }) {
  try {
    const resultData = yield call(mainApi.deleteMainItems, payload);

    yield put(mainAction.deleteMainItemsSuccess(resultData));
  } catch (error) {
    yield put(mainAction.deleteMainItemsError());
  }
}

function* changeOrder({ payload }) {
  try {
    const resultData = yield call(mainApi.changeOrder, payload);

    yield put(mainAction.changeOrderSuccess(resultData));
  } catch (error) {
    yield put(mainAction.changeOrderError());
  }
}

function* updateItem({ payload }) {
  try {
    const resultData = yield call(mainApi.updateItem, payload);

    yield put(mainAction.updateItemSuccess(resultData));
  } catch (error) {
    yield put(mainAction.updateItemError());
  }
}

export function* watchInsertMainContents() {
  yield takeLatest(mainAction.insertMainContents, insertMainContents);
}

export function* watchGetMainContentsList() {
  yield takeLatest(mainAction.getMainContentsList, getMainContentsList);
}
export function* watchGetMainDetail() {
  yield takeLatest(mainAction.getMainDetail, getMainDetail);
}
export function* watchDeleteMainItems() {
  yield takeLatest(mainAction.deleteMainItems, deleteMainItems);
}
export function* watchChangeOrder() {
  yield takeLatest(mainAction.changeOrder, changeOrder);
}
export function* watchUpdateItem() {
  yield takeLatest(mainAction.updateItem, updateItem);
}

function* rootSaga() {
  yield all([
    fork(watchInsertMainContents),
    fork(watchGetMainContentsList),
    fork(watchChangeOrder),
    fork(watchDeleteMainItems),
    fork(watchGetMainDetail),
    fork(watchUpdateItem),
  ]);
}

export default rootSaga;
