import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Image from "../../components/Image";
import { useNavigate } from "react-router";

const getRandomIndex = (exclude, length) => {
  let randomIndex;
  do {
    randomIndex = Math.floor(Math.random() * length);
  } while (randomIndex === exclude);
  return randomIndex;
};

const ImageGrid = ({ imageList, interval = 4000, isMobile }) => {
  const [gridImages, setGridImages] = useState([]);
  const [prevIndex, setPrevIndex] = useState(-1);
  const [cachedImages, setCachedImages] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // 🎯 이미지를 Blob으로 변환해 캐싱
    const fetchAndCacheImages = async () => {
      const cached = {};
      await Promise.all(
        imageList.map(async (image) => {
          if (!cached[image.filePath]) {
            try {
              const response = await fetch(image.filePath);
              const blob = await response.blob();
              cached[image.filePath] = URL.createObjectURL(blob);
            } catch (error) {
              console.error("이미지 로드 실패:", image.filePath);
            }
          }
        })
      );
      setCachedImages(cached);
    };

    fetchAndCacheImages();
  }, [imageList]);

  useEffect(() => {
    if (Object.keys(cachedImages).length === 0) return;

    // 🎯 초기 3x3 이미지 설정
    const initialImages = Array.from(
      { length: 9 },
      () => imageList[Math.floor(Math.random() * imageList.length)]
    );
    setGridImages(initialImages);

    // 🎯 이미지 교체 타이머
    const intervalId = setInterval(() => {
      setGridImages((prevGridImages) => {
        const newGridImages = [...prevGridImages];
        const randomGridIndex = Math.floor(Math.random() * 9);
        const randomImageIndex = getRandomIndex(prevIndex, imageList.length);

        newGridImages[randomGridIndex] = imageList[randomImageIndex];
        setPrevIndex(randomImageIndex);

        return newGridImages;
      });
    }, interval);

    return () => clearInterval(intervalId); // 클린업 함수
  }, [cachedImages, imageList, interval]);

  return (
    <Container isMobile={isMobile}>
      {gridImages.map((item, idx) => (
        <div key={idx} className="image-container">
          <Image
            onClick={() => navigate(`/contents/detail/${item.id}`)}
            src={cachedImages[item.filePath] || item.filePath} // 캐시된 Blob URL 사용
            alt={`img-${idx}`}
            className="image"
          />
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 10px;
  width: 100%;

  ${({ isMobile }) =>
    isMobile &&
    css`
      grid-template-columns: 1fr;
      opacity: 1 !important;
    `}

  .image-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    overflow: hidden;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;

    &:hover {
      transform: scale(0.9);
      filter: brightness(50%);
      cursor: pointer;
      transition: 0.3s ease;
    }
  }
`;

export default ImageGrid;
