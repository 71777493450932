import { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { ReactComponent as Menu } from "../../assest/svg/Menu.svg";
import { ReactComponent as CloseIcon } from "../../assest/svg/CloseIcon.svg";

import { ReactComponent as Link } from "../../assest/svg/Link.svg";
import { ReactComponent as Logo } from "../../assest/svg/logo.svg";
import { useMediaQuery } from "react-responsive";

const MenuItem = ({ isActive, onClick, text }) => {
  return (
    <Item isActive={isActive} onClick={onClick}>
      {text}
      <UnderLine isActive={isActive} />
    </Item>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1279px)",
  });

  const handleSelectMobileMenu = (path) => {
    navigate(path);
    setOpenSideMenu(false);
  };

  return (
    <Container isMobile={isMobile} theme={pathname === "/" ? "white" : "black"}>
      <LogoArea onClick={() => navigate("/")}>
        <Logo />
      </LogoArea>

      {!isMobile && (
        <>
          <MenuList>
            {/* <MenuItem
              isActive={pathname === "/about"}
              onClick={() => navigate("/about")}
              text={"About"}
            /> */}
            <MenuItem
              isActive={pathname.includes("contents")}
              onClick={() => navigate("/contents")}
              text={"Contents"}
            />
            <MenuItem
              onClick={() => {
                window.location.href = "https://www.instagram.com/studioaavec/";
              }}
              text={<>Instagram</>}
            ></MenuItem>
            <MenuItem
              onClick={() => {
                window.location.href = "https://pf.kakao.com/_zDCSb";
              }}
              text={<>Reservation</>}
            ></MenuItem>
          </MenuList>
        </>
      )}

      {isMobile && <Menu onClick={() => setOpenSideMenu(true)} />}

      {openSideMenu && (
        <MobileMenuContainer>
          <MenuList isMobile={isMobile}>
            <MobileHeader>
              <Logo onClick={() => handleSelectMobileMenu("/")} />
              <Circle onClick={() => setOpenSideMenu(false)}>
                <CloseIcon />
              </Circle>
            </MobileHeader>
            <div style={{ paddingLeft: "20px" }}>
              <MenuItem>
                <Logo />
              </MenuItem>
              {/* <MenuItem
                onClick={() => handleSelectMobileMenu("/about")}
                text={"About"}
              /> */}
              <MenuItem
                onClick={() => handleSelectMobileMenu("/contents")}
                text={"Contents"}
              />
              <MenuItem
                onClick={() => {
                  window.location.href =
                    "https://www.instagram.com/studioaavec/";
                }}
                text={<>Instagram</>}
              ></MenuItem>
              <MenuItem
                onClick={() => {
                  window.location.href = "https://pf.kakao.com/_zDCSb";
                }}
                text={<>Reservation</>}
              ></MenuItem>
            </div>
          </MenuList>
        </MobileMenuContainer>
      )}
    </Container>
  );
};

const Circle = styled.div`
  color: white;
`;

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  height: 72px;
`;

const MobileMenuContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  z-index: 9999;
  display: flex;
  flex-direction: column;

  & > div {
    height: 18rem;
  }
`;

const MenuList = styled.div`
  display: flex;
  color: white;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

const Item = styled.div`
  box-sizing: border-box;
  padding: 0 20px 0px 20px;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  text-align: center;
  font-weight: 600;
  ${({ isActive }) => isActive && css``}
`;

const UnderLine = styled.div`
  position: absolute;
  bottom: 0;
  height: 2%;
  left: 0;
  width: 100%;
  opacity: 0;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      background-color: white;
      transition: 0.3s ease;
    `}
`;

const Container = styled.header`
  width: calc(100% - 40px) !important;
  height: 72px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  top: 0;
  z-index: 999;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: calc(100% - 40px) !important;
      padding-left: 20px;
      padding-right: 20px;
      justify-content: space-between;
    `}

  transition: 0.3s ease;
  ${({ theme }) =>
    theme === "white"
      ? css`
          background: #11111180;
          position: absolute;
        `
      : css`
          background-color: black;
        `} /* justify-content: center; */



        ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
    `}
`;

const LogoArea = styled.div`
  width: 200px;
  cursor: pointer;
  position: relative;
  color: white;
  font-size: 2rem;
  img {
    width: 100%;
    object-fit: contain;
  }
`;

export default memo(Header);
